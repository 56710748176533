import * as React from 'react';
// import ReactDOM from 'react-dom';
import Button from '@mui/material/Button';

import theme from './theme'
import ComingSoon from "./pages/ComingSoon"

import { CssBaseline, ThemeProvider } from '@mui/material';


export default function App() {
  return  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ComingSoon/>
  </ThemeProvider>
}

//ReactDOM.render(<App />, document.querySelector('#app'));