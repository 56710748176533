import { createTheme, ThemeOptions } from "@mui/material/styles";

/**
 * See also [Theme Generator]{@link https://bareynol.github.io/mui-theme-creator/}
 */
const theme: ThemeOptions = {
  palette: {
    mode: "dark", // NOTE: used to be called `type`
    primary: {
      main: "#14140d",
      contrastText: "#cecc0f",
    },
    background: {
      default: "#232323",
      paper: "#212121",
    },
    text: {
      primary: "#cde61c",
      secondary: "rgba(229,234,23,0.7)",
    },
  },
  typography: {
    fontFamily: "Open Sans",
    h1: {
      fontFamily: "Ubuntu Mono",
    },
    h2: {
      fontFamily: "Ubuntu Mono",
    },
    h3: {
      fontFamily: "Ubuntu Mono",
    },
    h4: {
      fontFamily: "Ubuntu Mono",
    },
    h6: {
      fontFamily: "Ubuntu Mono",
    },
    h5: {
      fontFamily: "Ubuntu Mono",
    },
    subtitle1: {
      fontFamily: "Ubuntu Mono",
    },
    subtitle2: {
      fontFamily: "Ubuntu Mono",
    },
    button: {
      fontFamily: "Ubuntu Mono",
      fontWeight: 900,
    },
    overline: {
      fontFamily: "Ubuntu Mono",
    },
  },
};

export default createTheme(theme);
